import React from 'react';
import VideoBg from '../assets/img/video-bg.jpg'


const VideoSection = () => {

    return (
        <div className="video-section" style={{ backgroundImage: `url(${VideoBg})` }}>
            <div className="overlay"></div>
            <div className="video-inner-box">
                <div className="play-btn">
                    <a href="#" className="mfp-iframe"><i className="fa-solid fa-play"></i></a>
                </div>
            </div>
        </div>
    );
}



export default VideoSection;
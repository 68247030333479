import React from 'react';
import HeaderTopArea from "../component/HeaderTopArea";
import HeaderArea from "../component/HeaderArea";
import BreadcrumbArea from "../component/BreadcrumbArea";
import AboutSection from "../component/AboutSection";
import FeatureSection from "../component/FeatureSection";
import FeatureSlider from "../component/FeatureSlider";
import FaqSection from "../component/FaqSection";
import CtaSection from "../component/CtaSection";
import FooterArea from "../component/FooterArea";



const About = () => {

    return(
        <>
            <HeaderTopArea />
            <HeaderArea />
            <BreadcrumbArea />
            <AboutSection/>
            <FeatureSection/>
            {/*<FeatureSlider/>*/}
            <FaqSection />
            <CtaSection />
            <FooterArea />
        </>
    );

};


export default About;